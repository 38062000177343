import { createApp } from 'vue'
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css"
//import { initializeApp } from "firebase/app";
//import { getAuth } from "firebase/auth";
//import { getStorage } from "firebase/storage";
//import firebase from 'firebase/compat/app';
import Swal from 'sweetalert2';
import vSelect from 'vue-select';

//import 'firebase/compat/firestore';
//import 'firebase/compat/storage';
import 'vue-select/dist/vue-select.css';
import ProgressBar from "./components/ProgressBar"
import CurrencyInput from "./components/CurrencyInput"

//const firebaseApp = firebase.initializeApp(firebaseConfig);

//Initialize Firebase
//const app = initializeApp(firebaseConfig);

//const storage = getStorage();
//const db = firebaseApp.firestore();
//const auth = getAuth(app);

export { /*db, auth, storage,*/Swal };

const aplicacion = createApp(App);
aplicacion.component('v-select', vSelect);
aplicacion.component('progressBar', ProgressBar);
aplicacion.component('currencyInput', CurrencyInput);
aplicacion.mount('#app');
