<script>
import { onBeforeMount, reactive, computed } from 'vue';
import { Swal } from "./main";
import useValidate from '@vuelidate/core';
import { required, email, integer, helpers } from '@vuelidate/validators';
import { urlApiBackend } from './urlApiBackend.js'

export default {
  setup() {

    let backdrop = Swal.mixin({
      title: 'Validando...', width: 600, padding: 100, customClass: {}, background: 'rgba(170, 170, 170, 0)', color: 'rgb(255, 255, 255)', showConfirmButton: false, backdrop: true, allowOutsideClick: false, allowEscapeKey: false,
      //customClass: 'estilosBackdrop',
    })

    let estadoActual = reactive({ guardando: false })

    const swalToast = Swal.mixin({
      toast: true,
      position: 'center',
      //backdrop: false,
      showConfirmButton: false,
      timer: 3000,
      customClass: 'estilosSwalToast',
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    onBeforeMount(async () => {

      Swal.fire({
        title: "<strong>Importante</strong>",
        icon: "info",
        html: `
        Solo tiene 3 intentos, digite su numero de documento y fecha de expedicion del mismo si cuenta con el tiempo disponible, será alrededor de 5 minutos, tenga a la mano la informacion que se le especificó en el correo que recibio
        <br></br>
        <b>Tenga en cuenta:</b>
        <br>
        Al diligenciar el formulario, usted está aceptando nuestra politica de datos la cual fue adjunta en el correo en el cual se le informó el permiso para el acceso a esta plataforma
        `,
        showCloseButton: true,
        //showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: `OK`,
        confirmButtonAriaLabel: "Thumbs up, great!",
        //cancelButtonText: `<i class="fa fa-thumbs-down"></i>`,
        //cancelButtonAriaLabel: "Thumbs down"
      });


      //Swal.fire("Importante", "Solo tiene 3 intentos, digite su numero de documento y fecha de expedicion del mismo si cuenta con el tiempo disponible, será alrededor de 5 minutos, tenga a la mano la informacion que se le especificó en el correo que recibio", "info");
      console.clear();
    });

    let tiposDocumentos = reactive([]);
    let cargos = reactive([]);
    let ciudades = reactive([]);
    let estadosCiviles = reactive([]);
    let tiposVivienda = reactive([]);
    let cursos = reactive([]);
    let nivelesEducativos = reactive([]);
    let gruposSanguineos = reactive([])
    let generos = reactive([])
    let sedes = reactive([]);

    let datosAcceso = reactive({
      numeroDocumento: "",
      fechaExpedicionDocumento: ""
    })

    let aplicante = reactive({
      cargo: null,
      sede: null,
      tipoDocumento: null,
      numeroDocumento: null,
      lugarExpedicionDocumento: null,
      fechaExpedicionDocumento: null,
      fechaNacimiento: null,
      lugarNacimiento: null,
      grupoSanguineo: null,
      genero: null,
      primerNombre: null,
      segundoNombre: null,
      primerApellido: null,
      segundoApellido: null,
      nombreCompleto: null,
      //telefono: null,
      celular: null,
      direccion: null,
      ciudadResidencia: null,
      barrio: null,
      estadoCivil: null,
      email: null,
      numeroLibretaMilitar: null,
      tieneVehiculo: null,
      tipoVivienda: null,
      ultimaEmpresaDondeTrabajo: {
        nombre: null,
        direccion: null,
        telefono: null,
        jefeInmediato: null,
        cargo: null
      },
      familiarMasCercano: null,
      /*
      familiarMasCercano: {
        nombre: null,
        direccion: null,
        telefono: null,
        email: null
      },*/
      ultimoCursoRealizado: null,
      nivelEducativo: null,
      aptitudesTecnologicas: null,
      referenciasPersonal1: null,
      referenciasPersonal2: null,
      /*
      referenciasPersonal1: {
        nombre: null,
        direccion: null,
        telefono: null
      },
      referenciasPersonal2: {
        nombre: null,
        direccion: null,
        telefono: null
      },*/

      anosExperienciaLaboral: null,
      aspiracionSalarial: null,

      expectativasComentario: null,
    })
    /*
    const validarArchivo = (event) => {
      const tipoArchivo = event.target.files[0].type;
      if (tipoArchivo === 'application/pdf') {
        const tamanoKb = Math.round(event.target.files[0].size / 1024);
        console.log("tamaño en KB", tamanoKb);
        if (tamanoKb < 20480) {
          aplicante.archivoDocumentos = event.target.files[0];
          console.log(aplicante.archivoDocumentos);
        } else {
          event.target.value = null;
          Swal.fire("Error", "El archivo pesa " + tamanoKb + " KB, supera los 20 MB en peso", "error");
        }
      } else {
        //reset del input tipo file
        event.target.value = null;
        Swal.fire("Error", "Solo se admite archivo de tipo PDF", "error");
      }
    }*/

    const rules = computed(() => {
      return {
        cargo: { required: helpers.withMessage('Campo requerido', required) },
        sede: { required: helpers.withMessage('Campo requerido', required) },
        tipoDocumento: { required: helpers.withMessage('Campo requerido', required) },
        numeroDocumento: { required: helpers.withMessage('Campo requerido', required) },
        grupoSanguineo: { required: helpers.withMessage('Campo requerido', required) },
        lugarExpedicionDocumento: { required: helpers.withMessage('Campo requerido', required) },
        fechaNacimiento: { required: helpers.withMessage('Campo requerido', required) },
        lugarNacimiento: { required: helpers.withMessage('Campo requerido', required) },
        genero: { required: helpers.withMessage('Campo requerido', required) },
        primerNombre: { required: helpers.withMessage('Campo requerido', required) },
        primerApellido: { required: helpers.withMessage('Campo requerido', required) },
        celular: { required: helpers.withMessage('Campo requerido', required) },
        ciudadResidencia: { required: helpers.withMessage('Campo requerido', required) },
        barrio: { required: helpers.withMessage('Campo requerido', required) },
        direccion: { required: helpers.withMessage('Campo requerido', required) },
        estadoCivil: { required: helpers.withMessage('Campo requerido', required) },
        email: { required: helpers.withMessage('Campo requerido', required), email: helpers.withMessage('Debe ser un correo electronico', email) },
        tieneVehiculo: { required: helpers.withMessage("Campo requerido", required) },
        tipoVivienda: { required: helpers.withMessage("Campo requerido", required) },
        familiarMasCercano: { required: helpers.withMessage('Campo requerido', required) },
        /*
        familiarMasCercano: {
          nombre: { required: helpers.withMessage('Campo requerido', required) },
          direccion: { required: helpers.withMessage('Campo requerido', required) },
          telefono: { required: helpers.withMessage('Campo requerido', required) },
          email: { required: helpers.withMessage('Campo requerido', required), email: helpers.withMessage('Debe ser un correo electronico', email) }
        },*/
        nivelEducativo: { required: helpers.withMessage("Campo requerido", required) },
        referenciasPersonal1: { required: helpers.withMessage('Campo requerido', required) },
        referenciasPersonal2: { required: helpers.withMessage('Campo requerido', required) },
        /*
        referenciasPersonal1: {
          nombre: { required: helpers.withMessage('Campo requerido', required) },
          direccion: { required: helpers.withMessage('Campo requerido', required) },
          telefono: { required: helpers.withMessage('Campo requerido', required) },
        },
        referenciasPersonal2: {
          nombre: { required: helpers.withMessage('Campo requerido', required) },
          direccion: { required: helpers.withMessage('Campo requerido', required) },
          telefono: { required: helpers.withMessage('Campo requerido', required) },
        },*/

        anosExperienciaLaboral: { required: helpers.withMessage('Campo requerido', required), integer: helpers.withMessage("Solo puede contener numeros enteros", integer) },
        aspiracionSalarial: { required: helpers.withMessage("Campo requerido", required) },
        expectativasComentario: { required: helpers.withMessage("Campo requerido", required) },
      }
    })

    const rules2 = computed(() => {
      return {
        numeroDocumento: { required: helpers.withMessage('Campo requerido', required) },
        fechaExpedicionDocumento: { required: helpers.withMessage('Campo requerido', required) },
      }
    })

    const v$ = useValidate(rules, aplicante);
    const v2$ = useValidate(rules2, datosAcceso);

    const validarDatosFormulario = async () => {
      console.clear();
      const isFormCorrect = await v2$.value.$validate();
      if (isFormCorrect) {
        backdrop.fire();
        backdrop.showLoading();

        let url = `${urlApiBackend}/aplicante/validarAplicante/numeroDocumento/${datosAcceso.numeroDocumento}/fechaExpedicionDocumento/${datosAcceso.fechaExpedicionDocumento}`
        console.log('url', url)
        let rtaValidacionAplicante = await fetch(url, { method: 'GET', cache: 'no-cache', headers: { "Content-Type": 'Application/json' } });

        if (rtaValidacionAplicante.status === 200) {
          //obtengo entonces el email pues se muestra aca en un input deshabilitado pues ese no lo puede editar ya que es el mismo en el cual se le notificó que ya puede ingresar a aplicantes.caltseguridadltda.com
          let datosAplicanteValidado = await rtaValidacionAplicante.json()
          console.log('datosAplicanteValidado', datosAplicanteValidado)

          //como el aplicante fue validado, trago los datos para poblar el select, envio las tablas separadas por coma
          let tablasConsultar = 'tiposDocumentos,gruposSanguineos,generos,cargos,ciudades,estadosCiviles,tiposVivienda,cursos,nivelesEducativos,sedes';
          let urlTablasDatosDefecto = `${urlApiBackend}/datosDefecto/arrayTablasSQL/${tablasConsultar}`
          let rtaDatosDefectoJson = await fetch(urlTablasDatosDefecto, { method: 'GET', cache: 'no-cache', headers: { "Content-Type": 'Application/json' } });

          if (rtaDatosDefectoJson.status === 200) {
            let rtaDatosDefecto = await rtaDatosDefectoJson.json();
            rtaDatosDefecto.tiposDocumentos.forEach(el => { tiposDocumentos.push(el) })
            rtaDatosDefecto.gruposSanguineos.forEach(el => { gruposSanguineos.push(el) })
            rtaDatosDefecto.generos.forEach(el => { generos.push(el) })
            rtaDatosDefecto.cargos.forEach(el => { cargos.push(el) })
            rtaDatosDefecto.ciudades.forEach(el => { ciudades.push(el) })
            rtaDatosDefecto.estadosCiviles.forEach(el => { estadosCiviles.push(el) })
            rtaDatosDefecto.tiposVivienda.forEach(el => { tiposVivienda.push(el) })
            rtaDatosDefecto.nivelesEducativos.forEach(el => { nivelesEducativos.push(el) })

            rtaDatosDefecto.sedes.forEach(el => { sedes.push(el) })
          } else {
            let rtaError = await rtaDatosDefectoJson.json();
            alert(`Favor avisar a la empresa lo siguiente, hay un error en aplicantes.caltseguridadltda.com, error del servidor => ${rtaError.message}`)
          }

          visibilidad.aplicanteValidado = true;
          aplicante.numeroDocumento = datosAplicanteValidado.numeroDocumento;
          aplicante.fechaExpedicionDocumento = datosAplicanteValidado.fechaExpedicionDocumento;
          aplicante.email = datosAplicanteValidado.email;

          Swal.close();

        } else {
          let mensajeError = await rtaValidacionAplicante.json();
          Swal.fire("Error", mensajeError.message, "error");
        }
      }
    }

    const enviarPostulacion = async () => {
      console.clear();
      //console.log("se ejecutó enviarPostulacion")
      //Por si escribe y luego borra en los campos opcionales
      if (aplicante.segundoNombre == "") { aplicante.segundoNombre = null }
      if (aplicante.segundoApellido == "") { aplicante.segundoApellido = null }
      if (aplicante.segundoNombre != null && aplicante.segundoApellido != null) { aplicante.nombreCompleto = aplicante.primerNombre + " " + aplicante.segundoNombre + " " + aplicante.primerApellido + " " + aplicante.segundoApellido }
      if (aplicante.segundoNombre == null && aplicante.segundoApellido != null) { aplicante.nombreCompleto = aplicante.primerNombre + " " + aplicante.primerApellido + " " + aplicante.segundoApellido }
      if (aplicante.segundoNombre != null && aplicante.segundoApellido == null) { aplicante.nombreCompleto = aplicante.primerNombre + " " + aplicante.segundoNombre + " " + aplicante.primerApellido }
      if (aplicante.segundoNombre == null && aplicante.segundoApellido == null) { aplicante.nombreCompleto = aplicante.primerNombre + " " + aplicante.primerApellido }

      //console.log(aplicante);

      const isFormCorrect = await v$.value.$validate();
      if (isFormCorrect) {
        //console.log(aplicante);
        //estadoActual.guardando = true;
        swalToast.fire({ icon: 'info', title: 'Enviando postulacion...', timer: false });
        swalToast.showLoading();

        console.log('aplicante a guardar', aplicante)
        let url = `${urlApiBackend}/aplicante/numeroDocumento/${aplicante.numeroDocumento}`
        let rtaJson = await fetch(url, { method: 'PUT', body: JSON.stringify(aplicante), cache: 'no-cache', headers: { 'Content-Type': 'application/json' } })
        if (rtaJson.status === 200) {
          let rta = await rtaJson.json();
          console.log(rta);

          visibilidad.aplicanteValidado = false;

          //limpio campos
          aplicante.cargo = null;
          aplicante.sede = null;
          aplicante.tipoDocumento = null;
          aplicante.numeroDocumento = null;
          aplicante.lugarExpedicionDocumento = null;
          aplicante.fechaExpedicionDocumento = null;
          aplicante.fechaNacimiento = null;
          aplicante.lugarNacimiento = null;
          aplicante.grupoSanguineo = null;
          aplicante.genero = null;
          aplicante.primerNombre = null;
          aplicante.segundoNombre = null;
          aplicante.primerApellido = null;
          aplicante.segundoApellido = null;
          aplicante.nombreCompleto = null;
          aplicante.telefono = null;
          aplicante.celular = null;
          aplicante.direccion = null;
          aplicante.ciudadResidencia = null;
          aplicante.barrio = null;
          aplicante.estadoCivil = null;
          aplicante.email = null;
          aplicante.numeroLibretaMilitar = null;
          aplicante.tieneVehiculo = null;
          aplicante.tipoVivienda = null;
          aplicante.ultimaEmpresaDondeTrabajo.nombre = null;
          aplicante.ultimaEmpresaDondeTrabajo.direccion = null;
          aplicante.ultimaEmpresaDondeTrabajo.telefono = null;
          aplicante.ultimaEmpresaDondeTrabajo.jefeInmediato = null;
          aplicante.ultimaEmpresaDondeTrabajo.cargo = null;
          aplicante.familiarMasCercano = null;
          aplicante.ultimoCursoRealizado = null;
          aplicante.nivelEducativo = null;
          aplicante.aptitudesTecnologicas = null;
          aplicante.referenciasPersonal1 = null;
          aplicante.referenciasPersonal2 = null;
          aplicante.anosExperienciaLaboral = null;
          aplicante.aspiracionSalarial = null;
          aplicante.expectativasComentario = null;

          swalToast.fire({ icon: 'success', title: 'Postulacion enviada con exito', timer: false });

        }

        if (rtaJson.status === 500) {
          let rtaError = await rtaJson.json()
          alert('Favor tomar foto de este mensaje y comunicar a la empresa, hay un error al enviar la postulacion del aplicante =>', rtaError.message)
        }

      } else {
        estadoActual.guardando = false;
        console.log("validacion formulario", isFormCorrect);
        swalToast.fire({ icon: 'error', title: 'Revise los campos obligatorios', position: "center" });
      }
    }

    let visibilidad = reactive({
      aplicanteValidado: false
    })

    /*
    OJO, POR ALGUNA EXTRAÑA RAZON ESTE LIMPIAR CAMPOS ENVIA TODO EN NULL A LA FUNCIONA GUARDAR POSTULACION
    const limpiarCampos = () => {
   
      aplicante.tipoDocumento = null;
      aplicante.numeroDocumento = null;
      aplicante.primerNombre = null;
      aplicante.segundoNombre = null;
      aplicante.primerApellido = null;
      aplicante.segundoApellido = null;
      aplicante.nombreCompleto = null;
      aplicante.telefono = null;
      aplicante.celular = null;
      aplicante.ciudadResidencia = null;
      aplicante.estadoCivil = null;
      aplicante.email = null;
      aplicante.numeroLibretaMilitar = null;
      aplicante.tieneVehiculo = null;
      aplicante.tipoVivienda = null;
      aplicante.ultimaEmpresaDondeTrabajo.nombre = null;
      aplicante.ultimaEmpresaDondeTrabajo.direccion = null;
      aplicante.ultimaEmpresaDondeTrabajo.telefono = null;
      aplicante.ultimaEmpresaDondeTrabajo.jefeInmediato = null;
      aplicante.ultimaEmpresaDondeTrabajo.cargo = null;
      aplicante.familiarMasCercano = null;
      aplicante.ultimoCursoRealizado = null;
      aplicante.nivelEducativo = null;
      aplicante.aptitudesTecnologicas = null;      
      aplicante.referenciasPersonal1.nombre = null;
      aplicante.referenciasPersonal1.direccion = null;
      aplicante.referenciasPersonal1.telefono = null;
      aplicante.referenciasPersonal2.nombre = null;
      aplicante.referenciasPersonal2.direccion = null;
      aplicante.referenciasPersonal2.telefono = null;      
      aplicante.cargo = null;
      aplicante.anosExperienciaLaboral = null;
      aplicante.aspiracionSalarial = null;
      aplicante.archivoDocumentos = null;
      aplicante.expectativasComentario = null;
    }
    */



    return {
      datosAcceso,
      validarDatosFormulario,
      enviarPostulacion,
      visibilidad,
      estadoActual,
      aplicante,
      tiposDocumentos,
      cargos,
      ciudades,
      estadosCiviles,
      tiposVivienda,
      cursos,
      nivelesEducativos,
      gruposSanguineos,
      generos,
      sedes,
      v$,
      v2$
      //limpiarCampos
    }
  }
}
</script>
<template>
  <link rel="icon" type="image/png" href="./assets/favicon.ico" />
  <!--<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet"/>-->

  <div class="container mt-5">
    <div class="row align-items-center">
      <div class="col">
        <img src="./assets/logo.png" class="logo" height="200" width="700" />
      </div>
    </div>
    <div class="row align-items-center mt-3">
      <div class="col">
        <h2>APLICANTES</h2>
      </div>
    </div>
  </div>

  <!--{{ aplicante }}-->
  <div class="container mt-4" v-if="!visibilidad.aplicanteValidado">
    <div class="card">
      <div class="card-header pt-3">
        <h3>Diligenciar formulario</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="col text-start">
              <label class="form-label">Numero documento:</label>
              <input v-model="datosAcceso.numeroDocumento" type="text" class="form-control" />
              <small v-if="v2$.numeroDocumento.$error" class="text-danger mb-0">
                {{ v2$.numeroDocumento.$errors[0].$message }}</small>
            </div>
          </div>
          <div class="col text-start">
            <label class="form-label">Fecha de expedicion de documento:</label>
            <input v-model="datosAcceso.fechaExpedicionDocumento" :disabled="false" type="date" class="form-control">
            <small v-if="v2$.fechaExpedicionDocumento.$error" class="text-danger mb-0"></small>
          </div>
        </div>

        <div class="row mx-1 mt-4">
          <button class="btn btn-primary" @click="validarDatosFormulario()">
            <span class="material-icons md-24">login</span>
            Diligenciar formulario
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="container mt-4" v-if="visibilidad.aplicanteValidado">
    <div class="card">
      <div class="card-header">
        <h5 class="mt-2">SELECCIONE CARGO AL QUE ASPIRA Y EN QUE SEDE DE CALT SEGURIDAD LTDA</h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col text-start">
            <label class="text-danger">*</label><label class="form-label">Cargo al que aspira:</label>
            <v-select v-model="aplicante.cargo" :options="cargos" :clearable="false" label="nombre"
              placeholder="Seleccione..." :reduce="el => el.nombre" />
            <small v-if="v$.cargo.$error" class="text-danger mb-0">{{ v$.cargo.$errors[0].$message }}</small>
          </div>
          <div class="col text-start">
            <label class="text-danger">*</label><label class="form-label">Sede:</label>
            <v-select v-model="aplicante.sede" :options="sedes" :clearable="false" label="nombre"
              placeholder="Seleccione..." :reduce="el => el.nombre" />
            <small v-if="v$.sede.$error" class="text-danger mb-0">{{ v$.sede.$errors[0].$message }}</small>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-5">
      <div class="card-header">
        <h5 class="mt-2">DATOS PERSONALES</h5>
      </div>
      <div class="card-body">
        <div class="row mb-3">
          <div class="col text-start">
            <label class="text-danger">*</label><label for="formGroupExampleInput" class="form-label">Tipo
              de documento:</label>
            <v-select :clearable="false" placeholder="Seleccione..." class="style-chooser"
              v-model="aplicante.tipoDocumento" :options="tiposDocumentos" label="nombre"
              :reduce="td => td.abreviatura" />
            <small v-if="v$.tipoDocumento.$error" class="text-danger mb-0">Campo requerido</small>
          </div>
          <div class="col text-start">
            <label class="text-danger">*</label><label class="form-label">Numero de documento:</label>
            <input v-model="aplicante.numeroDocumento" type="text" class="form-control" />
            <small v-if="v$.numeroDocumento.$error" class="text-danger mb-0">Campo requerido</small>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col text-start">
            <label class="text-danger">*</label><label for="formGroupExampleInput" class="form-label">Fecha de expedicion
              documento:</label>
            <input v-model="aplicante.fechaExpedicionDocumento" :disabled="true" type="date" class="form-control" />
          </div>
          <div class="col text-start">
            <label class="text-danger">*</label><label class="form-label">Lugar expedicion documento:</label>
            <input v-model="aplicante.lugarExpedicionDocumento" type="text" class="form-control" />
            <small v-if="v$.lugarExpedicionDocumento.$error" class="text-danger mb-0">Campo requerido</small>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col text-start">
            <label class="text-danger">*</label><label class="form-label">Lugar nacimiento:</label>
            <input v-model="aplicante.lugarNacimiento" type="text" class="form-control" />
            <small v-if="v$.lugarExpedicionDocumento.$error" class="text-danger mb-0">Campo requerido</small>
          </div>
          <div class="col text-start">
            <label class="text-danger">*</label><label class="form-label">Grupo sanguineo:</label>
            <v-select :clearable="false" placeholder="Seleccione..." class="style-chooser"
              v-model="aplicante.grupoSanguineo" :options="gruposSanguineos" label="nombre" :reduce="td => td.nombre" />
            <small v-if="v$.grupoSanguineo.$error" class="text-danger mb-0">Campo requerido</small>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col text-start">
            <label class="text-danger">*</label><label for="formGroupExampleInput" class="form-label">Genero:</label>
            <v-select :clearable="false" placeholder="Seleccione..." class="style-chooser" v-model="aplicante.genero"
              :options="generos" label="nombre" :reduce="td => td.nombre" />
            <small v-if="v$.genero.$error" class="text-danger mb-0">Campo requerido</small>
          </div>
          <div class="col text-start">
            <label class="text-danger">*</label><label class="form-label">Fecha de nacimiento:</label>
            <input v-model="aplicante.fechaNacimiento" type="date" class="form-control" />
            <small v-if="v$.fechaNacimiento.$error" class="text-danger mb-0">Campo requerido</small>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col text-start">
            <label class="text-danger">*</label><label class="form-label">Primer nombre:</label>
            <input v-model="aplicante.primerNombre" type="text" class="form-control" />
            <small v-if="v$.primerNombre.$error" class="text-danger mb-0">Campo requerido</small>
          </div>
          <div class="col text-start">
            <label class="form-label">Segundo nombre:</label>
            <div class="input-group">
              <input v-model="aplicante.segundoNombre" type="text" class="form-control" />
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col text-start">
            <label class="text-danger">*</label><label class="form-label">Primer apellido:</label>
            <input v-model="aplicante.primerApellido" type="text" class="form-control" />
            <small v-if="v$.primerApellido.$error" class="text-danger mb-0">Campo requerido</small>
          </div>
          <div class="col text-start">
            <label class="form-label">Segundo apellido:</label>
            <input v-model="aplicante.segundoApellido" type="text" class="form-control" />
          </div>
        </div>

        <div class="row mb-3">
          <!--
          <div class="col text-start">
            <label class="form-label">Telefono:</label>
            <input v-model="aplicante.telefono" type="text" class="form-control" />
          </div>
          -->
          <div class="col text-start">
            <label class="text-danger">*</label><label class="form-label">Celular (Telefono si no tiene)</label>
            <input v-model="aplicante.celular" type="text" class="form-control" />
            <small v-if="v$.celular.$error" class="text-danger mb-0">Campo requerido</small>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col text-start">
            <label class="text-danger">*</label><label class="form-label">Ciudad de residencia</label>
            <v-select :clearable="false" v-model="aplicante.ciudadResidencia" :options="ciudades" label="nombre"
              placeholder="Seleccione..." :reduce="el => el.nombre" />
            <small v-if="v$.ciudadResidencia.$error" class="text-danger mb-0">Campo requerido</small>
          </div>

          <div class="col text-start">
            <label class="text-danger">*</label><label class="form-label">Dirección</label>
            <input v-model="aplicante.direccion" type="text" class="form-control" />
            <small v-if="v$.direccion.$error" class="text-danger mb-0">Campo requerido</small>
          </div>
          <div class="col text-start">
            <label class="text-danger">*</label><label class="form-label">Barrio</label>
            <input v-model="aplicante.barrio" type="text" class="form-control" />
            <small v-if="v$.barrio.$error" class="text-danger mb-0">Campo requerido</small>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col text-start">
            <label class="text-danger">*</label><label class="form-label">Estado civil</label>
            <v-select :clearable="false" v-model="aplicante.estadoCivil" :options="estadosCiviles" label="nombre"
              placeholder="Seleccione..." :reduce="el => el.nombre" />
            <small v-if="v$.estadoCivil.$error" class="text-danger mb-0">{{
              v$.estadoCivil.$errors[0].$message
            }}</small>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col text-start">
            <label class="text-danger">*</label><label>Correo electronico</label>
            <input v-model="aplicante.email" :disabled="true" type="text" class="form-control" />
            <small v-if="v$.email.$error" class="text-danger mb-0">{{ v$.email.$errors[0].$message }}</small>
          </div>
          <div class="col text-start">
            <label>Numero libreta militar</label>
            <input v-model="aplicante.numeroLibretaMilitar" type="text" class="form-control" />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col text-start">
            <label>Si tiene vehiculo, especifique cual, de lo contrario deje el campo vacio</label>
            <input v-model="aplicante.tieneVehiculo" type="text" class="form-control" />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col text-start">
            <label class="text-danger">*</label><label>Tipo de vivienda en la que reside</label>
            <v-select :clearable="false" v-model="aplicante.tipoVivienda" :options="tiposVivienda" label="nombre"
              placeholder="Seleccione..." :reduce="el => el.nombre" />
            <small v-if="v$.tipoVivienda.$error" class="text-danger mb-0">{{
              v$.tipoVivienda.$errors[0].$message
            }}</small>
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-4">
      <div class="card-header">
        <h5 class="mt-2">ULTIMA EMPRESA EN LA QUE LABORÓ</h5>
        <p class="text-muted">Dejar campos en blanco si no posee experiencia laboral</p>
      </div>
      <div class="card-body">
        <div class="row">
        </div>
        <div class="row">
          <div class="col text-start">
            <label class="form-label">Nombre empresa:</label>
            <input v-model="aplicante.ultimaEmpresaDondeTrabajo.nombre" type="text" class="form-control" />
          </div>
          <div class="col text-start">
            <label class="form-label">Dirección:</label>
            <input v-model="aplicante.ultimaEmpresaDondeTrabajo.direccion" type="text" class="form-control" />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col text-start">
            <label class="form-label">Telefono:</label>
            <input v-model="aplicante.ultimaEmpresaDondeTrabajo.telefono" type="text" class="form-control" />
          </div>
          <div class="col text-start">
            <label class="form-label">Jefe inmediato:</label>
            <input v-model="aplicante.ultimaEmpresaDondeTrabajo.jefeInmediato" type="text" class="form-control" />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col text-start">
            <label class="form-label">Cargo desempeñado:</label>
            <input v-model="aplicante.ultimaEmpresaDondeTrabajo.cargo" type="text" class="form-control" />
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-4">
      <div class="card-header">
        <h5 class="mt-2">FAMILIAR MAS CERCANO</h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col text-start">
            <label class="text-danger">*</label><label class="form-label">Especifique el nombre, direccion y telefono del
              familiar mas cercano:</label>
            <label class="form-label">Por ejemplo: Maria Antonia, Cra 11 # 22-33 Barrio Rosales - Bucaramanga,
              3151111111</label>
            <input v-model="aplicante.familiarMasCercano" type="text" class="form-control" />
            <small v-if="v$.familiarMasCercano.$error" class="text-danger mb-0">{{
              v$.familiarMasCercano.$errors[0].$message
            }}</small>
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-4">
      <div class="card-header">
        <h5 class="mt-2">FORMACIÓN</h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col text-start">
            <label class="form-label">Ultimo curso realizado:</label>
            <input v-model="aplicante.ultimoCursoRealizado" type="text" class="form-control" />
          </div>
          <div class="col text-start">
            <label class="text-danger">*</label><label class="form-label">Nivel educativo:</label>
            <v-select :clearable="false" v-model="aplicante.nivelEducativo" :options="nivelesEducativos" label="nombre"
              placeholder="Seleccione..." :reduce="el => el.nombre" />
            <small v-if="v$.nivelEducativo.$error" class="text-danger mb-0">{{
              v$.nivelEducativo.$errors[0].$message
            }}</small>
          </div>
        </div>
        <div class="row mt-3">
          <label class="form-label">Si tiene alguna experiencia manejando medios tecnologicos escribalos a continuacion,
            por ejemplo: Camaras de seguridad, lectores de ronda, excel, word, etc, si no, deje el campo vacio</label>
          <div class="col text-start">
            <input v-model="aplicante.aptitudesTecnologicas" type="text" class="form-control" />
          </div>

        </div>

      </div>
    </div>
    <div class="card mt-4">
      <div class="card-header">
        <h5 class="mt-2">REFERENCIAS PERSONALES</h5>
        <small class="mt-2">Especifique el nombre y telefono de dos referencias personales, por ejemplo: Hernan Andres
          Mejía -
          315 1111111</small>
      </div>
      <!--
        <div class="card-body">
        <div class="row">
          <div class="col text-start">
            <label class="text-danger">*</label><label class="form-label">Nombre:</label>
            <input v-model="aplicante.referenciasPersonal1.nombre" type="text" class="form-control" />
            <small v-if="v$.referenciasPersonal1.nombre.$error" class="text-danger mb-0">{{
              v$.referenciasPersonal1.nombre.$errors[0].$message
            }}</small>
          </div>
          <div class="col text-start">
            <label class="text-danger">*</label><label class="form-label">Dirección:</label>
            <input v-model="aplicante.referenciasPersonal1.direccion" type="text" class="form-control" />
            <small v-if="v$.referenciasPersonal1.direccion.$error" class="text-danger mb-0">{{
              v$.referenciasPersonal1.direccion.$errors[0].$message
            }}</small>
          </div>
          <div class="col text-start">
            <label class="text-danger">*</label><label class="form-label">Telefono:</label>
            <input v-model="aplicante.referenciasPersonal1.telefono" type="text" class="form-control" />
            <small v-if="v$.referenciasPersonal1.telefono.$error" class="text-danger mb-0">{{
              v$.referenciasPersonal1.telefono.$errors[0].$message
            }}</small>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col text-start">
            <label class="text-danger">*</label><label class="form-label">Nombre:</label>
            <input v-model="aplicante.referenciasPersonal2.nombre" type="text" class="form-control" />
            <small v-if="v$.referenciasPersonal2.nombre.$error" class="text-danger mb-0">{{
              v$.referenciasPersonal2.nombre.$errors[0].$message
            }}</small>
          </div>
          <div class="col text-start">
            <label class="text-danger">*</label><label class="form-label">Dirección:</label>
            <input v-model="aplicante.referenciasPersonal2.direccion" type="text" class="form-control" />
            <small v-if="v$.referenciasPersonal2.direccion.$error" class="text-danger mb-0">{{
              v$.referenciasPersonal2.direccion.$errors[0].$message
            }}</small>
          </div>
          <div class="col text-start">
            <label class="text-danger">*</label><label class="form-label">Telefono:</label>
            <input v-model="aplicante.referenciasPersonal2.telefono" type="text" class="form-control" />
            <small v-if="v$.referenciasPersonal2.telefono.$error" class="text-danger mb-0">{{
              v$.referenciasPersonal2.telefono.$errors[0].$message
            }}</small>
          </div>
        </div>
      </div>
      -->
      <div class="card-body">
        <div class="row">
          <div class="col text-start">
            <label class="text-danger">*</label><label class="form-label">Referencia personal 1:</label>
            <input v-model="aplicante.referenciasPersonal1" type="text" class="form-control" />
            <small v-if="v$.referenciasPersonal1.$error" class="text-danger mb-0">{{
              v$.referenciasPersonal1.$errors[0].$message }}</small>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col text-start">
            <label class="text-danger">*</label><label class="form-label">Referencia personal 2:</label>
            <input v-model="aplicante.referenciasPersonal2" type="text" class="form-control" />
            <small v-if="v$.referenciasPersonal2.$error" class="text-danger mb-0">{{
              v$.referenciasPersonal2.$errors[0].$message
            }}</small>
          </div>

        </div>
      </div>
    </div>

    <div class="card mt-4">
      <div class="card-header">
        <h5 class="mt-2">OTROS</h5>
      </div>

      <div class="card-body">

        <div class="row">
          <div class="col text-start">
            <label class="text-danger">*</label><label class="form-label">Años de experiencia laboral:</label>
            <input v-model="aplicante.anosExperienciaLaboral" type="text" class="form-control" />
            <small v-if="v$.anosExperienciaLaboral.$error" class="text-danger mb-0">{{
              v$.anosExperienciaLaboral.$errors[0].$message
            }}</small>
          </div>
          <div class="col text-start">
            <label class="text-danger">*</label><label class="form-label">Aspiracion salarial:</label>
            <currencyInput class="form-control" v-model.lazy="aplicante.aspiracionSalarial"
              :options="{ currency: 'USD' }" />
            <small v-if="v$.aspiracionSalarial.$error" class="text-danger mb-0">{{
              v$.aspiracionSalarial.$errors[0].$message
            }}</small>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col text-start">
            <label class="text-danger">*</label><label class="form-label">Mencione brevemente que expectativas tiene a
              nivel laboral, educativo y personal, indique como planea hacerlas realidad:</label>
            <textarea v-model="aplicante.expectativasComentario" class="form-control" maxlength="200" rows="5"></textarea>
            <span class="justify-content-end"
              v-if="aplicante.expectativasComentario != null && aplicante.expectativasComentario.length > 0">{{
                aplicante.expectativasComentario.length
              }}/200</span>
            <small v-if="v$.expectativasComentario.$error" class="text-danger mb-0">{{
              v$.expectativasComentario.$errors[0].$message }}</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row mx-0 my-4 d-flex justify-content-center align-items-center">
      <button v-if="!estadoActual.guardando" class="btn btn-primary" @click="enviarPostulacion()">
        <span class="material-icons md-24">send</span>Enviar postulación
      </button>
      <div v-else class="spinner-border" role="status"></div>
    </div>

    <!--{{ aplicante }}-->
  </div>
  <!--<router-view />-->
</template>



<style>
.form-label {
  text-align: left !important
}

/*Se le modifico la altura al vue-select para que coincida con los inputs*/
.style-chooser,
.vs__dropdown-toggle {
  height: 38px;
}

.estilosSwalToast {
  /*background: rgba(148, 148, 148, 0.9) !important;*/
  color: rgb(103, 103, 103) !important;
  backdrop-filter: blur(0.1px) !important;
}

body.swal2-shown>[aria-hidden="true"] {
  transition: 0.1s filter;
  filter: blur(10px);
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v139/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
  vertical-align: -6px;
  /*border: solid 1px #ccc;*/
  /*margin: 0px 0px 0px 0px;*/
}


/*
.avatar {
  vertical-align: middle;
  width: 60px;
  height: 60px;
  border-radius: 60%;
}
*/

.header {
  overflow: hidden;
  padding: 20px 20px;
  background: #e5e8e8;
  overflow: hidden;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.5);
  padding-bottom: 30px;
  /*text-align: center;*/
}

body {
  background: #ffffff;
  color: #2c3e50;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

a {
  color: inherit;
}
</style>