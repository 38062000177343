<template>
  <input ref="inputRef" type="text"/>
</template>

<script>
  //ESTE COMPONENTE ES PARA CREAR UN INPUT EL CUAL LEE DATOS Y LOS MUESTRA EN MONEDA EN EL INPUT
  //PERO EL VALOR ES PLANO SIENDO ENTERO
  import { watch } from 'vue'
  import { useCurrencyInput } from 'vue-currency-input'

  export default {
    name: 'CurrencyInput',
    props: {
      modelValue: Number, // Vue 2: value
      options: Object
    },
    setup(props) {
      const { inputRef, setOptions, setValue } = useCurrencyInput(props.options)
      
      watch(
      () => props.modelValue, // Vue 2: props.value
      (value) => {
        setValue(value)
      }
    )

    watch(
      () => props.options,
      (options) => {
        setOptions(options)
      }
    )

      return { inputRef }
    }
  }
</script>