let modoDev = false;
let urlApiBackend;

if (modoDev) {
    urlApiBackend = "http://localhost:4000/api";
} else {
    urlApiBackend = 'https://backendadmon.caltseguridadltda.com/api';
}

export { urlApiBackend };
